import { Bidding } from "../google/bidding/bidding";
import { CampaignSettings } from "../google/campaign-settings/campaign-settings";
import { GoogleHeader } from "../google/header/header";
import { HomePage } from "../google/homepage/homepage";
import { NewCampign } from "../google/new-campaign/new-campaign";
import { useRouter, Pages } from "../../context/router";
import { Budget } from "../google/budget/budget";

const PageMap = {
  [Pages.Homepage]: <HomePage />,
  [Pages.NewCampaign]: <NewCampign />,
  [Pages.Bidding]: <Bidding />,
  [Pages.CampaignSettings]: <CampaignSettings />,
  [Pages.Budget]: <Budget />,
};

const GoogleScreen = () => {
  const { currentPage } = useRouter();

  return (
    <div className="pt-2 pb-4 pr-2">
      <GoogleHeader />

      {PageMap[currentPage]}
    </div>
  );
};

export { GoogleScreen };
