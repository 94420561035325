import {
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  SpeakerWaveIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Card } from "../../card/card";
import { useState } from "react";
import cn from "classnames";

const objectiveList = [
  {
    icon: <TagIcon className="h-6 w-6 text-gray-600" />,
    title: "Sales",
    description: "Drive sales made online, in-app, over the phone or in-store",
    "data-step": 2,
  },
  {
    icon: <UsersIcon className="h-6 w-6 text-gray-600" />,
    title: "Leads",
    description:
      "Get leads and other conversions by encouraging customers to take action",
  },
  {
    icon: <CursorArrowRaysIcon className="h-6 w-6 text-gray-600" />,
    title: "Website traffic",
    description: "Get the right people to visit your website",
  },
  {
    icon: <DevicePhoneMobileIcon className="h-6 w-6 text-gray-600" />,
    title: "App promotion",
    description:
      "Get more installs, engagement and pre-registrations for your app",
  },
  {
    icon: <SpeakerWaveIcon className="h-6 w-6 text-gray-600" />,
    title: "Reach, awareness, and consideration",
    description:
      "Reach a broad audience and build interest in your products or brand",
  },
];

const ChooseYourObjective = ({ setStep }) => {
  const [selected, setSelected] = useState(-1);

  return (
    <Card title="Choose your objective">
      <p className="mb-3 text-gray-800 text-sm">
        Select an objective to tailor your experience to the goals and settings
        that will work best for your campaign.
      </p>
      <div className="grid grid-cols-4 gap-4">
        {objectiveList.map(({ icon, title, description, ...rest }, index) => (
          <div
            onClick={() => {
              setSelected(index);
              if (index === 0) setStep((step) => step + 1);
            }}
            className={cn("border p-3 space-y-2 cursor-pointer", {
              "border-gray-200": selected !== index,
              "border-[#1a73e8] border-2": selected === index,
            })}
            {...rest}
          >
            {icon}
            <h6 className="font-medium" {...rest}>
              {title}
            </h6>
            <p className="text-[#3c4043]" {...rest}>
              {description}
            </p>
          </div>
        ))}
      </div>
    </Card>
  );
};

export { ChooseYourObjective };
