import {
  ChevronDownIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  MegaphoneIcon,
  PlusIcon,
  TrophyIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "../../menu/menu";
import { Pages, useRouter } from "../../../context/router";

const HomePage = () => {
  const { setCurrentPage } = useRouter();

  return (
    <div className="flex h-full gap-4 mt-2">
      <div className="flex h-full w-[280px]">
        <div className="flex flex-col items-center gap-5 p-2 border-r border-gray-300">
          <Menu
            trigger={
              <div className="flex flex-col items-center gap-1 cursor-pointer">
                <div className="w-14 h-14 rounded-full bg-white shadow-lg flex items-center justify-center">
                  <PlusIcon className="w-8 h-8 text-red-400" />
                </div>
                <span className="text-xs">Create</span>
              </div>
            }
            menuList={[
              {
                name: "Campaign",
                onClick: () => setCurrentPage(Pages.NewCampaign),
                "data-step": 1,
              },
              { name: "Ad group" },
              { name: "Search Keyword" },
              { name: "Conversion action" },
            ]}
          />
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <MegaphoneIcon className="w-6 h-6" />
            <span className="text-xs">Campaign</span>
          </div>
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <TrophyIcon className="w-6 h-6" />
            <span className="text-xs">Goals</span>
          </div>
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <WrenchScrewdriverIcon className="w-6 h-6" />
            <span className="text-xs">Tools</span>
          </div>
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <CreditCardIcon className="w-6 h-6" />
            <span className="text-xs">Billing</span>
          </div>
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <Cog6ToothIcon className="w-6 h-6" />
            <span className="text-xs">Admin</span>
          </div>
        </div>
        <div className="space-y-2 w-full font-medium text-sm pt-2 cursor-pointer">
          <div className="p-2 bg-[#1967d2] text-white rounded-r-2xl">
            Overview
          </div>
          <div className="p-2">Recommendations</div>
          <div className="relative p-2">
            Insights and reports
            <ChevronDownIcon className="w-5 h-5 absolute right-0 top-[10px]" />
          </div>
          <div className="relative p-2">
            Campaigns
            <ChevronDownIcon className="w-5 h-5 absolute right-0 top-[10px]" />
          </div>
          <div className="relative p-2">
            Assets
            <ChevronDownIcon className="w-5 h-5 absolute right-0 top-[10px]" />
          </div>
          <div className="relative p-2">
            Audiences,keywords, and content
            <ChevronDownIcon className="w-5 h-5 absolute right-0 top-[10px]" />
          </div>
          <div className="p-2">Change history</div>
        </div>
      </div>
      <div className="mt-2 w-full">
        <div className="flex gap-2">
          <div className="flex flex-col p-2 pr-12 shadow-md bg-white relative text-xs">
            <span className="">View (2 filters)</span>
            <span className="font-medium text-sm">All campaign</span>
            <ChevronDownIcon className="w-5 h-5 absolute right-[5px] top-[15px]" />
          </div>
          <div className="flex flex-col p-2 pr-12 border border-gray-500 rounded relative text-xs">
            <span className="">Campaigns (310)</span>
            <span className="font-medium text-sm">Select a campaign</span>
            <ChevronDownIcon className="w-5 h-5 absolute right-[5px] top-[15px]" />
          </div>
        </div>
        <div className="flex items-start gap-2 pb-3 border-b border-gray-500 mt-4 flex-1">
          <span className="text-xs font-medium">Filters</span>
          <div className="flex items-center gap-4">
            <div className="border border-gray-500 rounded-md p-1 text-xs">
              Campaign Status: Enabled, Paused
            </div>
            <div className="border border-gray-500 rounded-md p-1 text-xs">
              Ad Group Status: Enabled, Paused
            </div>
            <span className="text-xs text-gray-500">Add Filter</span>
          </div>
        </div>
        <h6 className="mt-4 text-xl">Overview</h6>
      </div>
    </div>
  );
};

export { HomePage };
