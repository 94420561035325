import {
  ArrowPathIcon,
  Bars3Icon,
  BellIcon,
  BuildingOfficeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { AdsIcon } from "../ads-icon/ads-icon";

const GoogleHeader = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Bars3Icon className="h-8 w-8 text-gray-500" />
        <div className="flex items-center gap-3 mx-6">
          <AdsIcon />
          <span className="text-2xl text-gray-500">
            <strong className="font-semibold">Google</strong> Ads
          </span>
        </div>
        <input
          className="border border-[#80868b] text-gray-500 bg-transparent px-2 rounded-md min-w-[350px] h-12"
          placeholder="Sayfa veya kampanya arayın"
        />
      </div>
      <div className="flex gap-3">
        <div className="flex items-center gap-3 border-r border-gray-300 pr-3">
          <div className="flex flex-col items-center gap-1">
            <BuildingOfficeIcon className="w-6 h-6 text-gray-500" />
            <span className="text-xs">Görünüm</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <ArrowPathIcon className="w-6 h-6 text-gray-500" />
            <span className="text-xs">Yenile</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
            <span className="text-xs">Yardım</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <BellIcon className="w-6 h-6 text-gray-500" />
            <span className="text-xs">Bildirimler</span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex flex-col text-right">
            <span className="text-xs">234-234-3334</span>
            <span className="text-xs">burakcan@gmail.com</span>
          </div>
          <div className="w-8 h-8 rounded-full bg-[#7b20a2] font-semibold grid place-items-center text-white">
            b
          </div>
        </div>
      </div>
    </div>
  );
};

export { GoogleHeader };
