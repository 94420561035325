import { Card } from "../../card/card";

const Budget = () => {
  return (
    <div className="pt-10 px-24 space-y-4">
      <div>
        <h1 className="text-[#202124] font-medium text-xl">Budget</h1>
        <p className="text-sm text-gray-500 mt-1">
          Select the average you want to spend each day.
        </p>
      </div>
      <Card title="Budget">
        <div className="flex justify-between text-sm text-gray-500 font-medium">
          <div className="flex items-start gap-2">
            <div className="flex flex-col gap-2">
              <span>Set your average daily budget for this campaign</span>
              <input
                className="w-52 h-10 border border-gray-300 rounded-sm pl-2"
                value="TRY"
              />
            </div>
          </div>
          <div className="pl-4 border-l border-gray-300 max-w-[300px]">
            <p>
              By default, your campaign bids equally for new and existing
              customers. However, you can configure your customer acquisition
              settings to optimize for acquiring new customers. Learn more
            </p>
          </div>
        </div>
      </Card>
      <div className="flex justify-end">
        <button className="px-4 py-2 bg-blue-600 rounded-sm text-sm text-white font-medium ml-2">
          Publish
        </button>
      </div>
    </div>
  );
};

export { Budget };
