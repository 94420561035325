import { useState } from "react";
import {
  DocumentCheckIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "../../menu/menu";
import { Card } from "../../card/card";
import { useTutorial } from "../../../context/tutorial";

const ChooseGoal = ({ setStep }) => {
  const { tutorial } = useTutorial();
  const [goals, setGoals] = useState([
    {
      text: "Book appointments",
      onRemoved: () =>
        setGoals(goals.filter((goal) => goal.text !== "Book appointments")),
    },
    {
      text: "Sign-ups",
      onRemoved: () => null,
    },
  ]);

  return (
    <Card title="Use these conversion goals to improve Sales">
      <p className="text-gray-800 text-sm">
        Conversion goals labled as account default will use data from all of
        your campaings to improve your bid strategy and campaign performance,
        even if they don't seem directly related to Sales.
      </p>
      <div className="mt-6">
        <div className="p-2 grid grid-cols-[1fr,200px,200px,200px] text-xs font-medium text-gray-500">
          <span>Conversion Goals</span>
          <span>Conversion Source</span>
          <span>Conversion Actions</span>
          <span></span>
        </div>
        <div className="space-y-4 text-sm">
          {goals.map((goal, index) => (
            <div
              key={index}
              className="p-2 grid grid-cols-[1fr,200px,200px,200px] border border-gray-200 rounded text-gray-500"
            >
              <div className="flex items-center">
                <DocumentCheckIcon className="w-5 text-gray-900 mr-2" />
                <b className="font-semibold">{goal.text}</b> (account default)
              </div>
              <span className="font-semibold">website</span>
              <span>1 action</span>

              <div className="ml-auto pr-4">
                <Menu
                  trigger={
                    <EllipsisVerticalIcon className="w-5 text-gray-900" />
                  }
                  menuList={[
                    {
                      name: `Remove "${goal.text}" goal`,
                      "data-step": 3,
                      onClick: goal.onRemoved,
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-max">
        <Menu
          trigger={<span className="text-[#1967d2] mt-4">Add goal</span>}
          menuList={[
            { name: "Engagements" },
            {
              name: "Page views",
              "data-step": 4,
              onClick: () => {
                if (tutorial !== 3) {
                  return;
                }

                setGoals([
                  { text: "Page View", onRemoved: () => null },
                  ...goals,
                ]);

                setStep(3);
              },
            },
          ]}
        />
      </div>
    </Card>
  );
};

export { ChooseGoal };
