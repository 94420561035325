import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Card } from "../../card/card";
import { Menu } from "../../menu/menu";
import { Pages, useRouter } from "../../../context/router";
import { useTutorial } from "../../../context/tutorial";

const Bidding = () => {
  const { tutorial } = useTutorial();
  const { setCurrentPage } = useRouter();
  const [isChecked, setChecked] = useState(false);

  return (
    <div className="pt-10 px-24 space-y-4">
      <h1 className="text-[#202124] font-medium text-xl">Bidding</h1>
      <Card title="Bidding">
        <div className="w-max">
          <span className="block mb-2 text-sm text-gray-500 font-medium">
            What do you want to focus on?
          </span>
          <Menu
            trigger={
              <div className="flex items-center gap-2 border border-gray-600 rounded-md p-3">
                Conversions
                <ChevronDownIcon className="w-5 h-5 text-gray-600" />
              </div>
            }
            menuList={[
              { name: "Conversions" },
              { name: "Conversion value", "data-step": 6 },
            ]}
          ></Menu>
        </div>
        <div className="flex items-center gap-2 mt-6">
          <input
            checked={isChecked}
            onChange={() => {
              if (tutorial === 6) {
                setChecked(!isChecked);
              }
            }}
            className="w-4 h-4 rounded-sm"
            data-step="7"
            type="checkbox"
          />
          <span className="text-sm text-gray-500 font-medium">
            Set a target cost per action (optional)
          </span>
        </div>
        {isChecked && (
          <div className="mt-3">
            <span className="block mb-2 text-sm text-gray-500 font-medium">
              Target ROAS
            </span>
            <input
              className="border border-gray-500 rounded-md p-2 w-[80px] text-right"
              value="%"
            />
          </div>
        )}
      </Card>
      <Card title="Customer acquisition">
        <div className="flex justify-between text-sm text-gray-500 font-medium">
          <div className="flex items-start gap-2">
            <input className="w-4 h-4 rounded-sm" type="checkbox" />
            <div className="flex flex-col gap-2">
              <span>Bid for new customers only</span>
              <span className="text-xs">
                this option limits your ads to only new customers, regardless of
                your bid strategy
              </span>
            </div>
          </div>
          <div className="pl-4 border-l border-gray-300 max-w-[300px]">
            <p>
              By default, your campaign bids equally for new and existing
              customers. However, you can configure your customer acquisition
              settings to optimize for acquiring new customers. Learn more
            </p>
          </div>
        </div>
      </Card>
      <div className="flex justify-end">
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-md"
          data-step="8"
          onClick={() => {
            if (tutorial === 7) {
              setCurrentPage(Pages.CampaignSettings);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export { Bidding };
