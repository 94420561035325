import { Card } from "../../card/card";

const CampaignName = () => {
  return (
    <Card title="Campaign Name">
      <input
        className="border border-gray-500 rounded-sm h-12 px-4 min-w-[300px]"
        value="Sales-Performance Max-11"
      />
    </Card>
  );
};

export { CampaignName };
