import { createContext, useContext, useState } from "react";

const RouterContext = createContext();

export const Pages = {
  Homepage: "Homepage",
  NewCampaign: "NewCampaign",
  Bidding: "Bidding",
  CampaignSettings: "CampaignSettings",
  Budget: "Budget",
};

const RouterProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(Pages.Homepage);

  return (
    <RouterContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </RouterContext.Provider>
  );
};

export { RouterProvider };

export const useRouter = () => useContext(RouterContext);
