import { useState } from "react";
import { CampaignName } from "./campaign-name";
import { ChooseGoal } from "./choose-goal";
import { ChooseYourObjective } from "./choose-your-objective";
import { SelectCampaignType } from "./select-campaign-type";
import { Pages, useRouter } from "../../../context/router";
import { useTutorial } from "../../../context/tutorial";

const NewCampign = () => {
  const [step, setStep] = useState(0);
  const { setCurrentPage } = useRouter();
  const { tutorial } = useTutorial();

  const FormSteps = [
    <ChooseYourObjective setStep={setStep} key={0} />,
    <ChooseGoal setStep={setStep} key={1} />,
    <SelectCampaignType setStep={setStep} key={2} />,
    <CampaignName setStep={setStep} key={3} />,
  ];

  return (
    <div className="pt-10 px-24 space-y-4">
      <h1 className="text-[#202124] font-medium text-xl">
        What is your campign objective?
      </h1>
      {new Array(step + 1).fill(0).map((_, index) => FormSteps[index])}

      <div className="flex items-center justify-end gap-6 mt-5">
        <a href="#" className="text-[#1967d2]">
          Cancel
        </a>
        <button
          className="bg-[#1a73e8] text-white p-2 rounded"
          data-step="5"
          onClick={() => {
            if (tutorial === 4) {
              setCurrentPage(Pages.Bidding);
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export { NewCampign };
