import React, { useState } from "react";
import Select, { components } from "react-select";
import { Card } from "../../card/card";
import { Pages, useRouter } from "../../../context/router";
import { useTutorial } from "../../../context/tutorial";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input className="mr-1" type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const allOptions = [
  { value: "Dutch", label: "dutch" },
  { value: "Chinese", label: "Chinese" },
  { value: "English", label: "English" },
  { value: "Danish", label: "Danish" },
];

const CampaignSettings = () => {
  const { tutorial, setTutorial } = useTutorial();
  const { setCurrentPage } = useRouter();
  const [languages, setLanguages] = useState([]);

  return (
    <div className="pt-10 px-24 space-y-4">
      <div>
        <h1 className="text-[#202124] font-medium text-xl">
          Campaign settings
        </h1>
        <p className="text-sm text-gray-500 mt-1">
          To reach the right people, start by defining key settings for your
          campaign
        </p>
      </div>
      <Card title="Locations">
        <span className="block mb-3 text-base text-gray-500 font-medium">
          Select locations to target
        </span>
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-md text-gray-500 font-medium">
            <input checked type="radio" name="location" />
            <span>All countries and territories</span>
          </div>
          <div className="flex items-center gap-2 text-md text-gray-500 font-medium">
            <input type="radio" name="location" />
            <span>Turkiye</span>
          </div>
          <div className="flex items-center gap-2 text-md text-gray-500 font-medium">
            <input type="radio" name="location" />
            <span>Enter another location</span>
          </div>
        </div>
      </Card>
      <Card title="Languages">
        <span className="block mb-3 text-base text-gray-500 font-medium">
          Select the languages your customers speak.
        </span>
        <div>
          <Select
            isMulti
            value={languages}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={(options) => {
              if (Array.isArray(options)) {
                setLanguages(options);
                console.log(
                  "languages",
                  options,
                  options.some((opt) => opt.value === "English")
                );
                if (options.some((opt) => opt.value === "English")) {
                  setTutorial(9);
                }
              }
            }}
            options={allOptions}
            placeholder="Start typing or select a language"
            components={{
              Option: InputOption,
            }}
          />
        </div>
      </Card>
      <div className="flex justify-end">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          data-step="10"
          onClick={() => {
            if (tutorial === 9) {
              setCurrentPage(Pages.Budget);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export { CampaignSettings };
