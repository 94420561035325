import { Fragment, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";

const Menu = ({ menuList, trigger }) => {
  const ref = useRef(null);
  return (
    <Popover className="relative text-start">
      <Popover.Button
        ref={ref}
        className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
      >
        {trigger}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
          <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {menuList.map(({ name, href, onClick, ...rest }) => (
              <a
                key={name}
                href={href}
                className="block p-2 hover:text-indigo-600 cursor-pointer"
                onClick={() => {
                  onClick?.();

                  setTimeout(() => {
                    ref.current?.click();
                  }, 0);
                }}
                {...rest}
              >
                {name}
              </a>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export { Menu };
