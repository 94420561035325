import { Campaign } from "../campaign/campaign";
import { GoogleScreen } from "../google-screen/google-screen";

const Content = () => {
  return (
    <div className="grid grid-cols-[1fr_3fr] gap-2 min-h-[calc(100vh-64px)]">
      <Campaign />
      <GoogleScreen />
    </div>
  );
};

export { Content };
