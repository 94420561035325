import {
  Bars3Icon,
  ArrowLeftIcon,
  ArrowRightIcon,
  TrophyIcon,
  DevicePhoneMobileIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
const Navbar = () => {
  const outlineButtonStyles = `p-[8px] rounded-[5px] border-[2px] border-solid border-black`;

  return (
    <header className="p-[10px] bg-white">
      <div className="flex justify-between items-center">
        <div className="logo">
          <a href="/" className="text-2xl text-turquoise">
            digimarky
          </a>
        </div>
        <div className="flex">
          <button
            className={`${outlineButtonStyles} rounded-tr-none rounded-br-none border-r-0`}
          >
            <ArrowLeftIcon width={20} height={20} />
          </button>
          <button className="flex items-center py-[8px] px-[20px] gap-x-5 border-solid border-[2px] border-black">
            <Bars3Icon width={20} height={20} />
            <span>Course Outline</span>
          </button>
          <button
            className={`${outlineButtonStyles} rounded-tl-none rounded-bl-none border-l-0`}
          >
            <ArrowRightIcon width={20} height={20} />
          </button>
        </div>
        <div className="flex gap-x-[15px] items-center">
          <span className="flex items-center gap-x-[8px]">
            <TrophyIcon width={20} height={20} />
            <span className="font-bold">Daily XP</span>
            <span className="text-pink font-bold">600</span>
          </span>
          <span className="h-[20px] w-[20px] bg-green inline-block rounded-[50%]"></span>
          <DevicePhoneMobileIcon width={20} height={20} />
          <PencilSquareIcon width={20} height={20} />
        </div>
      </div>
    </header>
  );
};

export { Navbar };
