import { createContext, useContext, useEffect, useState } from "react";

const TutorialContext = createContext({});

const TutorialProvider = ({ children }) => {
  const [tutorial, setTutorial] = useState(0);

  useEffect(() => {
    const handleStepCompleted = (e) => {
      const step = e.target.getAttribute("data-step");
      if (step) {
        setTutorial((tutorial) => {
          if (tutorial === parseInt(step) - 1) {
            return tutorial + 1;
          }

          return tutorial;
        });
      }
    };

    document.addEventListener("click", handleStepCompleted);

    return () => {
      document.removeEventListener("click", handleStepCompleted);
    };
  }, []);

  return (
    <TutorialContext.Provider value={{ tutorial, setTutorial }}>
      {children}
    </TutorialContext.Provider>
  );
};

export { TutorialProvider };

export const useTutorial = () => useContext(TutorialContext);
