const Card = ({ title, children }) => {
  return (
    <div className="bg-white rounded-md">
      <div className="p-5 border-b border-gray-200">
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="p-5">{children}</div>
    </div>
  );
};

export { Card };
