import { Content } from "./components/content/content";
import { Navbar } from "./components/navbar";
import { RouterProvider } from "./context/router";
import { TutorialProvider } from "./context/tutorial";

function App() {
  return (
    <TutorialProvider>
      <RouterProvider>
        <div className="App">
          <Navbar />
          <Content />
        </div>
      </RouterProvider>
    </TutorialProvider>
  );
}

export default App;
