import {
  NewspaperIcon,
  CheckCircleIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { CampaignCard } from "./campaign-card/campaign-card";
import { useTutorial } from "../../context/tutorial";

const Campaign = () => {
  const tutorialSteps = [
    "Click 'Create' button and click 'Campaign'",
    "Choose 'Sales' objective",
    "Remove 'Book appointments'",
    "Add 'Page Views' Goal",
    "Click 'Continue' button",
    "Choose 'Conversion value'",
    "Select 'Set a target cost per action (optional)' checkbox",
    "Click 'Next' button",
    "Choose 'English' language from languages selectbox",
    "Click 'Next' button",
  ];

  const { tutorial } = useTutorial();

  return (
    <div className="relative flex flex-col">
      <div className="campaign-section">
        <div className="flex gap-[8px] items-center pl-4 py-2">
          <NewspaperIcon width={20} height={20} />
          <span>Exercise</span>
        </div>
        <CampaignCard title="Creating a Campaign">
          <p className="mt-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </CampaignCard>
      </div>
      <div className="campaign-section flex flex-1 flex-col relative">
        <div className="flex gap-[8px] items-center p-[10px]">
          <CheckCircleIcon width={20} height={20} />
          <span>Instructions</span>
        </div>
        <CampaignCard
          title="Creating a Campaign"
          className="flex flex-col flex-1"
        >
          <ul className="mt-5 space-y-3 text-sm">
            {tutorialSteps.map((t, index) => (
              <li>
                <span className="flex gap-[5px] items-center">
                  {index + 1}-) {t}
                  {tutorial >= index + 1 && (
                    <CheckCircleIcon
                      className="w-5 min-w-[20px] h-5"
                      color="green"
                    />
                  )}
                </span>
              </li>
            ))}
          </ul>
          <button className="flex gap-3 items-center bg-gray-200 rounded p-3 ml-auto mr-auto mt-auto">
            <LightBulbIcon width={20} height={20} />
            Take Hint (-30 XP)
          </button>
        </CampaignCard>
      </div>
    </div>
  );
};

export { Campaign };
