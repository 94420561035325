const AdsIcon = () => {
  return (
    <svg
      height="32"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 230"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m5.888 166.405103 84.992-145.505103c10.796138 6.3558621 65.235862 36.4844138 74.028138 42.2135172l-84.9920001 145.5139308c-9.2954482 12.279173-85.8041379-23.58731-74.0281379-42.231172z"
        fill="#fbbc04"
      />
      <path
        d="m250.084224 166.401789-84.992-145.4962759c-11.881931-19.7737931-37.473103-26.95944827-58.491586-15.28055172-21.018483 11.67889652-27.418483 36.83751722-15.5365519 57.49406892l84.9919999 145.5139307c11.881931 19.764966 37.473104 26.950621 58.491586 15.271725 20.100414-11.678897 27.418483-37.729104 15.536552-57.485242z"
        fill="#4285f4"
      />
      <ellipse
        cx="42.663724"
        cy="187.924414"
        fill="#34a853"
        rx="42.663724"
        ry="41.604414"
      />
    </svg>
  );
};

export { AdsIcon };
