const CampaignCard = (props) => {
  return (
    <div className={`bg-white p-5 ${props.className}`}>
      <h2 className="text-2xl font-extrabold dark:text-white">{props.title}</h2>
      {props.children}
    </div>
  );
};

export { CampaignCard };
